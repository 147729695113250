<template>
  
  <router-view></router-view>
  
</template>

<script>

// import Mine from './components/Mine.vue'


export default {
  name: 'App',
  components: {
    // Mine,
    // About
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Andale Mono";
  /* letter-spacing: 1px;
  word-spacing: 1px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
