import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import App from './App.vue'
const VueRouter = require("vue-router")
import Mine from './components/Mine.vue'
// import Flexflow from './components/Flexflow.vue'
import About from './components/About.vue'
import '../public/font/font.css'

const routes = [
  { path: '/', component: Mine },
  //{ path: '/', component: Flexflow },
  { path: '/about', component: About },
]
const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes, 
})

const app = createApp(App)
app.use(router)
// app.use(ElementPlus)
app.mount('#app')
