<template>
  <div class="body" >
    <div class="highlight-btn-group" :class="{'highlight-btn-group-small': smallHeader}">
      <button class="highlight-btn highlight-btn-sm-wide" 
        :class="{'highlight-btn-small': smallHeader, 'btn-sm': smallHeader, 'highlight-btn-active': highlighBtnActive==0}"
        @click="() => {highlightTags=['illustration', 'commercial']; highlighBtnActive=0}"
        @mouseover="() => {if (!isMobile()) highlightBtnAllText='全部'}"
        @mouseleave="highlightBtnAllText='All'">
        {{highlightBtnAllText}}
      </button>
      <button class="highlight-btn highlight-btn-wide" 
        :class="{'highlight-btn-small': smallHeader, 'btn-sm': smallHeader, 'highlight-btn-active': highlighBtnActive==1}"
        @click="() => {highlightTags=['commercial']; highlighBtnActive=1}"
        @mouseover="() => {if (!isMobile()) highlightBtnCommercialText='商稿'}"
        @mouseleave="highlightBtnCommercialText='Commercial'">
        {{highlightBtnCommercialText}}
      </button>
      <button class="highlight-btn highlight-btn-wide" 
        :class="{'highlight-btn-small': smallHeader, 'btn-sm': smallHeader, 'highlight-btn-active': highlighBtnActive==2}"
        @click="() => {highlightTags=['illustration']; highlighBtnActive=2}"
        @mouseover="() => {if (!isMobile()) highlightBtnIllustrationText='插画'}"
        @mouseleave="highlightBtnIllustrationText='Illustration'">
        {{highlightBtnIllustrationText}}
      </button>
    </div>
    <div class="header float-none d-flex justify-content-between" :class="{'header-small': smallHeader}">
      <div class="avatar-left" :class="{'avatar-small': smallHeader}">
        <router-link  to="/">
          <img class="avatar-img" :class="{'avatar-img-small': smallHeader}" src="./avatar1.jpg">
        </router-link>
        <router-link 
          to="/" 
          class="avatar-name" 
          :class="{'avatar-name-small': smallHeader}"
          @mouseover="avatarName='亦闻'"
          @mouseleave="avatarName='Yiwen Wang'"
          >
          {{avatarName}}
        </router-link>
      </div>
      <div class="avatar-right"  :class="{'avatar-right-small': smallHeader}">
        <router-link to="/about" class="avatar-about" :class="{'avatar-about-small': smallHeader}"
          @mouseover="avatarAboutText='关于'"
          @mouseleave="avatarAboutText='About'">
          {{avatarAboutText}}
        </router-link>
      </div>
    </div>
    <!-- <div class="main" :style="{height: '1000px'}"> -->
    <div class="main" :class="{'main-small': smallHeader}">  
      <!-- <div class="mine_background" :style="{height: '2800px'}"> -->
      <div 
        class="main-background"
        :style="{'min-height': innerHeight, height: bgHeight}"
        >
        <img 
          class="main-background-img" 
          src="./bg1.jpg" 
          @load="mainBackgroundLoaded"
          :style="{'min-height': innerHeight, width: bgWidth, height: bgHeight}">
      </div>
      <div 
        class="gold" 
        :class="{'gold-animationActive': group.animationActive, 'gold-isShy': isGoldShy(group)}"
        v-for="group in work" 
        :key="group.path"
        :style="{top: group.top, left: group.left, width: group.size, height: group.size, visibility: goldVisibility}"
        @click="toggleCarousel(group)">
        <div class="gold-avatar">
          <img class="gold-avatar-img" :src="group.path + '.jpg'">
        </div>
        <!-- <div class="gold-tags" :class="{'gold-tags-small': smallHeader}">
          <span class="badge secondary-badge">

            {{group.date}}
          </span>
          
        </div> -->

        <!-- <div 
          class="gold-shadow"
          :style="{width: group.size, height: group.size, top: '0px'}">
          <span v-for="(text, idx) in group.description" :key="`${group.path}description${idx}`">
            {{text}}
          </span>
        </div> -->
      </div>
      
    </div>
    
    
    <div 
      class="dialog-background"
      @click="closeCarousel"
      :style="{display: centerDialogVisible, width: dialogWidth, height: dialogHeight, top: dialogTop, left: dialogLeft}"  
      >
      <div 
        class="myCarousel"
        @click="preventClick"
        :key="carouselUpdate"
        ref="elcarouselcontainer"
        :style="{width: carouselWidth, height: carouselHeight, top: carouselTop, left: carouselLeft}">
          <!-- el-carousel implementation -->
          <!-- <el-carousel 
            :height="elCarouselHeight" 
            :autoplay="false"
            :pause-on-hover="false"
            :interval="999999999"
            :initial-index="0"
            trigger="click"
            indicator-position="outside"
            ref="elcarousel">
            <el-carousel-item 
              v-for="idx in selectedGroup.numPics" 
              :key="selectedGroup.path + '/' + idx + '.jpg'"
              :name="selectedGroup.path + '/' + idx + '.jpg'">
              <img
                :src="selectedGroup.path + '-' + idx + '.jpg'"
                :style="{height: carouselImgHeight}">
            </el-carousel-item>
          </el-carousel> -->
          <!-- bootstrap implementation -->
          <div 
            id="carouselExampleFade" 
            data-interval="false"
            data-bs-interval="false"
            class="carousel slide carousel-fade" 
            data-bs-ride="carousel"
            ref="elcarousel"
            
            >
            <div class="carousel-indicators">
              <button 
                v-for="(n, idx) in selectedGroup.numPics"
                :key="selectedGroup.path + '/' + idx + '.jpgbtn'"
                type="button" 
                data-bs-target="#carouselExampleFade" 
                :data-bs-slide-to="idx" 
                :class="{active: idx==selectedGroup.activeIndex}" 
                >
              </button>
            </div>
            <div class="carousel-inner">
              <div 
                class="carousel-item"
                :class="{active: idx==selectedGroup.activeIndex}"
                v-for="(n, idx) in selectedGroup.numPics"
                :key="selectedGroup.path + '/' + (idx+1) + '.jpg'"
                :style="{'height': carouselImgHeight}">
                <img
                  class="carousel-inner-img"
                  :src="selectedGroup.path + '-' + (idx+1) + (selectedGroup.specialFiles[idx+1]? selectedGroup.specialFiles[idx+1]: '.jpg')"
                  :style="computeImgStyle"
                  ref="preload">
                  <!-- :style="{'height': carouselImgHeight}" -->
              </div>
              
            </div>
            <!-- <button class="carousel-control-prev" type="button" @click="bsCarouselButton('prev')"> -->
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <!-- <button class="carousel-control-next" type="button" @click="bsCarouselButton('next')"> -->
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
      </div>
        
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Mine',
  props: {
    goldSize: {
      type: String,
      required: false,
      default: "245px"
    },
  },
  data() {
    return {
      highlighBtnActive: 0,
      avatarName: "Yiwen Wang",
      highlightBtnCommercialText: "Commercial",
      highlightBtnIllustrationText: "Illustration",
      highlightBtnAllText: "All",
      avatarAboutText: "About",
      highlightTags: ["commercial", "illustration"],
      bgLoaded: false,
      preloadImagesInterval: -1,
      preloadImagesIndex: 0,
      marginHorizontalOffset: 0,
      marginVerticalOffset: 140,
      smallHeader: false,
      heightWidthRatio: 1,
      screenWidth: null,
      screenHeight: null,
      innerHeight: 100,
      devWidth: 2379 - 70 - 70,
      devHeight: 3803 - 140,
      goldVisibility: "hidden",
      centerDialogVisible: "none",
      bgWidth: "100%",
      bgHeight: "auto",
      dialogWidth: null,
      dialogHeight: null,
      dialogTop: null,
      dialogLeft: null,
      carouselWidth: null,
      carouselHeight: null,
      carouselTop: null,
      carouselLeft: null,
      elCarouselHeight: "100px",
      carouselImgHeight: "100px",
      arouselImgStyle: "height: 100%",
      carouselAutoPlay: false,
      selectedGroup: null,
      carouselClicked: false,
      carouselPlayInterval: null,
      carouselPlayWindow: 4000,
      carouselUpdate: 0,
      imgRatio: 1.5, // width : height
      devPositions: [
        {
          devTop: "456px",
          devLeft: "204px"
        },
        {
          devTop: "560px",
          devLeft: "729px"
        },
        {
          devTop: "458px",
          devLeft: "1550px"
        },
        {
          devTop: "958px",
          devLeft: "66px"
        },
        {
          devTop: "850px",
          devLeft: "950px"
        },
        {
          devTop: "850px",
          devLeft: "1789px"
        },
        {
          devTop: "994px",
          devLeft: "1395px"
        },
        {
          devTop: "1283px",
          devLeft: "234px"
        },
        {
          devTop: "1218px",
          devLeft: "660px"
        },
        {
          devTop: "1328px",
          devLeft: "1655px"
        },
        {
          devTop: "1544px",
          devLeft: "862px"
        },
        {
          devTop: "1734px",
          devLeft: "1870px"
          //
        },
        {
          devTop: "2030px",
          devLeft: "1350px"
        },
        {
          devTop: "2100px",
          devLeft: "130px"
        },
        {
          devTop: "2200px",
          devLeft: "500px"
        },
        {
          devTop: "2430px",
          devLeft: "1000px"
        },
        {
          devTop: "2600px",
          devLeft: "400px"
        },
        {
          devTop: "2700px",
          devLeft: "1470px"
        },
        {
          devTop: "2800px",
          devLeft: "1950px"
        },
      ],
      work: [
        {
          path: "./18",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 8,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "C/LENS Visual Design",
          specialFiles: {
            
          },
          tags: ["illustration"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./17",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 9,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "C/LENS Visual Design",
          specialFiles: {
            
          },
          tags: ["illustration"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./16",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 6,
          shadowVisible: false,
          activeIndex: 0,
          date: "2023",
          title: "C/LENS Visual Design",
          specialFiles: {
            6: ".gif"
          },
          tags: ["commercial"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./15",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 12,
          shadowVisible: false,
          activeIndex: 0,
          date: "2023",
          title: "C/LENS Visual Design",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./14",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 9,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "C/LENS Visual Design",
          specialFiles: {},
          tags: ["illustration", "commercial"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./13",
          devSize: this.goldSize,
          top: "0px",
          left: "px",
          size: "450px",
          numPics: 8,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "C/LENS Visual Design",
          specialFiles: {},
          tags: ["commercial"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./12",
          devSize: this.goldSize,
          top: "800px",
          left: "400px",
          size: "450px",
          numPics: 4,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "Cats and Dogs",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf"
          ]
        },
        {
          path: "./11",
          devSize: this.goldSize,
          top: "950px",
          left: "1200px",
          size: "450px",
          numPics: 7,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "Wake up! Let's try flying!",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./10",
          devSize: this.goldSize,
          top: "1600px",
          left: "100px",
          size: "450px",
          numPics: 5,
          shadowVisible: false,
          activeIndex: 0,
          date: "2022",
          title: "Watercolor and color pencil",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./9",
          devSize: this.goldSize,
          top: "1450px",
          left: "860px",
          size: "450px",
          numPics: 4,
          shadowVisible: false,
          activeIndex: 0,
          date: "2021",
          title: "The difference between comic and graphic novel",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./8",
          devTop: "1400px",
          devLeft: "1530px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1470px",
          size: "450px",
          numPics: 7,
          shadowVisible: false,
          activeIndex: 0,
          date: "2021",
          title: "Liang and his magic brush",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./7",
          devTop: "1800px",
          devLeft: "520px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 8,
          shadowVisible: false,
          activeIndex: 0,
          date: "2021",
          title: "Collage",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./6",
          devTop: "1850px",
          devLeft: "1170px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 5,
          shadowVisible: false,
          activeIndex: 0,
          date: "2021",
          title: "Feuergeist",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./5",
          devTop: "2200px",
          devLeft: "170px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 4,
          shadowVisible: false,
          activeIndex: 0,
          date: "2020",
          title: "Schnittlauch",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./4",
          devTop: "2300px",
          devLeft: "850px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 5,
          shadowVisible: false,
          activeIndex: 0,
          date: "2020",
          title: "Bookstore",
          specialFiles: {},
          tags: ["illustration", "commercial"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./3",
          devTop: "2200px",
          devLeft: "1570px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 5,
          shadowVisible: false,
          activeIndex: 0,
          date: "2020",
          title: "Outsider",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./2",
          devTop: "2800px",
          devLeft: "370px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 4,
          shadowVisible: false,
          activeIndex: 0,
          date: "2019",
          title: "Hello Leshan",
          specialFiles: {},
          tags: ["commercial", "illustration"],
          description: [
            "asdfasdf",
          ]
        },
        {
          path: "./1",
          devTop: "2800px",
          devLeft: "1370px",
          devSize: this.goldSize,
          top: "1600px",
          left: "1670px",
          size: "450px",
          numPics: 5,
          shadowVisible: false,
          activeIndex: 0,
          date: "2019",
          title: "Chinese Zodiac",
          specialFiles: {},
          tags: ["illustration"],
          description: [
            "asdfasdf",
          ]
        },
      ]
    }
  },
  created() {
    this.selectedGroup = this.work[0]
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
        this.innerHeight = (window.innerHeight - 50) + "px"
        this.updateWork()
        this.updateBg()
        this.updateCarousel()
        this.updateDialogue()
        this.updateHeader()
      })()
    }
    let self = this
    window.addEventListener('keyup', function(event) {
      if (event.key === "Escape") {
        self.closeCarousel()
      }
    })
    this.selectedGroup = this.work[this.preloadImagesIndex]
    this.preloadImagesIndex += 1
    this.preloadImagesInterval = setInterval(function() {
      self.preloadImages()
      self.updateBg()
    }, 1000)
  },
  watch: {
    screenWidth: {
      handler: function() {
        this.updateWork()
        this.updateBg()
        this.updateCarousel()
        this.updateDialogue()
        this.updateHeader()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    computeTop() {
      return (devTop) => {
        
        let px = devTop.slice(0, -2)
        let top = Math.max(parseFloat(px), 141)
        // px = originalLeft.slice(0, -2)
        // let left = Math.max(parseFloat(px), 71)
        // px = newLeft.slice(0, -2)
        // let nLeft = Math.max(parseFloat(px), 71)
        let newTop = Math.round( (top - 140) / (this.devWidth - this.marginHorizontalOffset * 2) * (this.screenWidth - this.marginHorizontalOffset * 2) )
        // if (this.smallHeader) {
        //   newTop *= this.heightWidthRatio
        // } 
        return `${newTop}px`
      }
    },
    computeLeft() {
      return (originalLeft) => {
        // console.log(originalLeft)
        let px = originalLeft.slice(0, -2);
        let magnitude = parseInt(px)
        // .main-background has 70px margin at left and right.
        let newMagnitude = Math.round(magnitude / this.devWidth * (this.screenWidth - this.marginHorizontalOffset * 2) + this.marginHorizontalOffset)
        return `${newMagnitude}px`
      }
    },
    computeSize() {
      return (originalSize) => {
        let px = originalSize.slice(0, -2);
        let magnitude = parseInt(px)
        let newMagnitude = magnitude / this.devWidth * (this.screenWidth - this.marginHorizontalOffset * 2)
        if (this.smallHeader) {
          newMagnitude *= 1.2
        }
        return `${newMagnitude}px`
      }
    },
    computeImgStyle() {
      if (this.smallHeader) {
        return "width: 100%; height: 100%"
      }
      return "height: 100%"
    },
    isGoldShy() {
      return (group) => {
        for (let tag of this.highlightTags) {
          if (group.tags.indexOf(tag) > -1) {
            return false
          }
        }
        return true
      }
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    preloadImages() {
      if (this.centerDialogVisible === "none") {
        console.log(`preload ${this.preloadImagesIndex}`)
        this.selectedGroup = this.work[this.preloadImagesIndex]
        this.preloadImagesIndex += 1
        if (this.preloadImagesIndex >= this.work.length) {
          clearInterval(this.preloadImagesInterval)
        }
      }
    },
    updateHeader() {
      let ratio = window.innerHeight / window.innerWidth
      this.heightWidthRatio = ratio
      // console.log(ratio)
      if (ratio > 1) {
        this.smallHeader = true
      } else {
        this.smallHeader = false
      }
    },
    bsCarouselButton(action) {
      
      if (action === "prev") {
        let tmp = this.selectedGroup.activeIndex
        tmp -= 1
        if (tmp < 1) {
          tmp = this.selectedGroup.numPics
        }
        this.selectedGroup.activeIndex = tmp
      } else {
        let tmp = this.selectedGroup.activeIndex
        tmp += 1
        if (tmp > this.selectedGroup.numPics) {
          tmp = 1
        }
        this.selectedGroup.activeIndex = tmp

      }
    },
    updateBg() {
      this.bgWidth = `${this.screenWidth - 2 * this.marginHorizontalOffset}px`
      let idx = this.work.length - 1
      let top = parseFloat(this.work[idx].top.slice(0, -2))
      let goldSize = parseFloat(this.work[idx].size.slice(0, -2))
      this.bgHeight = `${top + goldSize * 1.5}px`
      // if (this.smallHeader) {
      //   this.bgHeight = `${window.innerHeight}px` 
      // }
      // console.log(this.bgWidth, this.screenWidth, document.body.clientWidth, this.bgHeight)
    },
    updateWork() {
      let res = {}

      for(let idx in this.work) {
        let group = this.work[idx]
        group.left = this.computeLeft(this.devPositions[idx].devLeft)
        group.top = this.computeTop(this.devPositions[idx].devTop)
        group.size = this.computeSize(group.devSize)
        
      }
      return res
    },
    updateCarousel() {
      let dialogGapRatio = 0.1
      let ratio = window.innerWidth / window.innerHeight
      let diff = ratio - this.imgRatio
      if (diff > 0) {
        this.carouselTop = Math.floor(window.innerHeight * dialogGapRatio / 2) + "px"
        this.carouselLeft = Math.floor(window.innerWidth * dialogGapRatio / 2) + "px"
        this.carouselWidth = Math.floor(window.innerWidth * (1-dialogGapRatio)) + "px"
        this.carouselHeight = Math.floor(window.innerHeight * (1-dialogGapRatio)) + "px"
      } else {
        this.carouselWidth = window.innerWidth + 'px'
        let tmpHeight = Math.floor(window.innerWidth / this.imgRatio)
        this.carouselHeight = tmpHeight + 'px'
        this.carouselTop = Math.floor((window.innerHeight - tmpHeight) / 2) + "px"
        this.carouselLeft = "0px"
      }
      this.elCarouselHeight = this.carouselHeight
      this.carouselImgHeight = this.carouselHeight
    },
    updateDialogue() {
      
      // this.dialogWidth = this.screenWidth + "px"
      // this.dialogHeight = this.screenHeight + "px"
      // this.dialogTop = "0px"
      // this.dialogLeft = "0px"
      this.dialogWidth = window.innerWidth + "px"
      this.dialogHeight = window.innerHeight + "px"
      this.dialogTop = "0px"
      this.dialogLeft = "0px"
    },
    mainBackgroundLoaded() {
      this.bgLoaded = true
      this.screenWidth = document.body.clientWidth
      this.screenHeight = document.body.clientHeight
      this.innerHeight = (window.innerHeight - 50) + "px"
      this.updateWork()
      this.goldVisibility = "visible"
      this.updateCarousel()
      this.updateDialogue()
              
      this.updateBg()
      this.updateHeader()
    },
    nextPicture() {
      // this.$refs.elcarousel.next()
    },
    toggleCarousel(group) {
      this.carouselUpdate += 1
      this.centerDialogVisible = "block"
      this.selectedGroup = group
      this.carouselClicked = true
      this.selectedGroup.animationActive = true
    },
    closeCarousel(e) {
      this.centerDialogVisible = "none"
      this.carouselAutoPlay = false
      this.carouselClicked = false
      if (e) {
        e.stopPropagation()
      }
      for (let group of this.work) {
        group.animationActive = false
      }
    },
    preventClick(e) {
      this.carouselClicked = true
      
      if (e) {
        e.stopPropagation()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Andale Mono";
}
.highlight-btn-group {
  position: fixed;
  top: 51px;
  /* left: 240px; */
  right: 163px;
  z-index: 11;
  
}
.highlight-btn {
  margin-left: 0px;
  font-weight: 800;
  font-size: 140%;
  font-family: "Andale Mono";
  /* letter-spacing: 1px; */
  background-color: #20202000;
  border-style: none;
  box-shadow: initial;
}
.highlight-btn-group button:focus {
  box-shadow: initial;
}
.highlight-btn-active {
  color: rgb(88, 88, 88)
}
.highlight-btn-wide {
  min-width: 180px;
}
.highlight-btn-sm-wide {
  min-width: 70px;
}
.highlight-btn-group-small {
  position: fixed;
  top: initial;
  left: initial;
  bottom: 10px;
  right: 10px; 
}
.highlight-btn-small {
  margin-left: 1px;
  opacity: 0.8;
  background-color: rgba(226, 226, 226, 0.877);
  font-size: 90%;
}
.highlight-btn-small.highlight-btn-wide {
  min-width: 100px;
}
.highlight-btn-small.highlight-btn-sm-wide {
  min-width: 40px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 10;
}
.header-small {
  height: 50px;
  padding-bottom: 15px;
}
.avatar-left {
  position: relative;
  top: 25%;
  width: 70px;
  height: 70px;
  margin-left: 70px;
  /* border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black; */
  display: flex;
  justify-content: left;
  flex-direction: row; 
}
.avatar-right {
  position: relative;
  top: 25%;
  height: 70px;
  margin-right: 0px;
  /* border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black; */
  display: flex;
  justify-content: left;
  flex-direction: row; 
}
.avatar-right-small {  
  margin-right: 10px;
}
.avatar-small {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 15px;
}
.avatar:hover {
  cursor: pointer;
}
.avatar-img {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
.avatar-img-small{
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.avatar-name {
  margin-top: 15px;
  margin-left: 10px;
  height: 70px;
  font-weight: 900;
  font-size: 145%;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: "Andale Mono";
  /* text-transform: lowercase; */
  text-decoration: none;
  /* color: rgb(53, 53, 53); */
  color: rgb(31, 31, 31);
  min-width: 190px;
}
.avatar-name-small {
  margin-top: 5px;
  margin-left: 5px;
  height: 35px;
  font-weight: 900;
  font-size: 120%;
}
.avatar-about {
  margin-top: 17px;
  margin-right: 50px;
  height: 70px;
  font-weight: 800;
  font-size: 140%;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family: "Andale Mono"; */
  font-family: "Andale Mono";
  text-decoration: none;
  color: rgb(53, 53, 53);
  min-width: 105px;

}
.avatar-about:hover {
  color: rgb(88, 88, 88);
}
.avatar-about-small {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 35px;
  font-weight: 900;
  font-size: 120%;
}
.menu {
  position: relative;
  top: 25%;
  margin-right: 70px;
  font-weight: 100;
}
.menu-button {
  border-radius: 5px;
  background-color: #f0efef;
  font-weight: 900;
  font-size: 120%;
  color: #656565;
  padding: 20px;
}
.menu-button:hover {
  color: #4c4c4c;
  background-color: #f4f4f4;
  cursor: pointer;
}
.main {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-content: center;
  margin-top: 140px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.main-small {
  margin-top: 50px;
}
.main-background {
  width: 100%;
  height: 100%;
  /* margin: 0px 0px 0px 0px; */
  overflow: hidden;
  /* background-image: url("@/assets/bg1.jpg"); */
  /* background-attachment: fixed; */
  /* background-size:cover; */
}
.main-background-img {
  /* align-self: top; */
  object-fit: cover;
  object-position: 0 0;
}
.gold {
  position: absolute;
  top: 700px;
  left: 450px;
  height: 450px;
  width: 500px;
  border-radius: 50px;
  transition: transform 2s;
}

.gold:hover {
  cursor: pointer;
  transform: scale(1.5);
  
  /* animation-delay: 2s; */
  /* animation-iteration-count: infinite; */
}
.gold-animationActive {
  transform: scale(1.5);
}
.gold-isShy {
  transform: scale(0.3);
}
.gold-shadow {
  position: absolute;
  color: #69696900;
  background-color: #69696900;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;
}
.gold-shadow:hover {
  color: white;
  background-color: #696969d2;
}
.gold-avatar {
  width: 100%;
  height: 100%;
  /* background-color: white; */
}
.gold-avatar:hover {
  animation: shake 1s infinite 2s alternate;
  animation-direction: alternate;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-2px, 0px) rotate(1deg); }
  30% { transform: translate(2px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, 0px) rotate(-1deg); }
}
.gold-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.gold-tags{
  color: white;
  /* font-size: 50%; */
}
.gold-tags-small {
  font-size: 1px;
}
.dialog-background {
  position: fixed;
  background-color: #202020ec;
  z-index: 11;
  justify-content: center;
  align-content: center;
}
.myCarousel {
  position: absolute;
  background-color: #505050af;
  border-radius: 12px;
  z-index: 12;
  /* justify-content: center;
  align-content: center; */
}

</style>
