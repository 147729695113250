<template>
  <div class="body" :style="{'background-image': 'url(bg2.jpg)'}">
    <div class="header float-none d-flex justify-content-between" :class="{'header-small': smallHeader}">
      <div class="avatar-left" :class="{'avatar-small': smallHeader}">
        <router-link  to="/">
          <img class="avatar-img" :class="{'avatar-img-small': smallHeader}" src="./avatarblack.jpg">
        </router-link>
        <router-link 
          to="/" 
          class="avatar-name" 
          :class="{'avatar-name-small': smallHeader}"
          @mouseover="avatarName='亦闻'"
          @mouseleave="avatarName='Yiwen Wang'"
          >
          {{avatarName}}
        </router-link>
      </div>
      <div class="avatar-right"  :class="{'avatar-right-small': smallHeader}">
        <router-link to="/about" class="avatar-about" :class="{'avatar-about-small': smallHeader}">About</router-link>
      </div>
    </div>
    <div class="contact-icons">
      <div class="d-flex flex-row-reverse bd-highlight">
        <div class="p-2 bd-highlight">
          <div class="contact-icon-bg"></div>
          <a href="https://mp.weixin.qq.com/s/KLXN-1rouK9AvpUCfqCnIQ" target="_blank">
            <img class="contact-icon-img" src="wechaticon.png" alt="">
          </a>
        </div>
        <div class="p-2 bd-highlight">
          <div class="contact-icon-bg"></div>
          <a href="https://www.xiaohongshu.com/user/profile/5afda6014eacab246f365a59?xhsshare=CopyLink&appuid=6208a1030000000010007cc1&apptime=1658418834" target="_blank">
            <img class="contact-icon-img" src="redbookicon.png" alt="">
          </a>
        </div>
        <div class="p-2 bd-highlight">
          <div class="contact-icon-bg"></div>
          <a href="https://www.instagram.com/yiwenwwwang/" target="_blank">
            <img class="contact-icon-img" src="insicon.png" alt="">
          </a>
        </div>
        <div class="p-2 bd-highlight">
          <div class="contact-icon-bg"></div>
          <a href="mailto:yiwen.wang@burg-halle.de" target="_blank">
            <img class="contact-icon-img" src="emailicon.png" alt="">
          </a>
        </div>
      </div>
    </div>
    <div class="body-container" :class="{'container': !smallHeader, 'body-container-small': smallHeader}">

      <div class="container body-avatar-container">
        <img class="body-avatar" :class="{'body-avatar-small': smallHeader}" src="./avatar1.jpg">
      </div>
      <br class="small">
      <h3 class="introduction-title">
        Yiwen Wang
      </h3>
      <div class="introduction">
        <p>
          yiwen.wang@burg-halle.de
        </p>
        <br class="small">
        <p>
          Yiwen is an illustrator who is intially from China, currently living in Germany.
          After studying financial engineering in Shanghai and data analysis in the USA,
          she moved to Germany to study illustration under Prof. ATAK.
        </p>
        <br class="small">
        <p>
          She likes to collaborate with people from different backgrounds to create 
          interesting designs.
        </p>

      </div>
      <br>
      <h3 class="introduction-title">
        亦闻闻闻闻
      </h3>
      <div class="introduction">
        <p class="chinese">
          亦闻是一位来自中国的插画师,现居住于德国。她曾求学于上海学习金融工程,
          而后求学于美国学习数据分析,几经波折之后来到德国学习插画专业,师从ATAK教授。
        </p>
        
        <br class="small">
        <p class="chinese">
          她喜欢与不同行业的人一起合作创造出有趣的设计。
        </p>
    </div>
      
    </div>
  </div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'About',
  props: {},
  data() {
    return {
      smallHeader: false,
      screenWidth: null,
      avatarName: "Yiwen Wang"
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.updateHeader()
      })()
    }
  },
  watch: {
    screenWidth: {
      handler: function() {
        this.updateHeader()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateHeader() {
      let ratio = window.innerHeight / window.innerWidth
      this.heightWidthRatio = ratio
      // console.log(ratio)
      if (ratio > 1) {
        this.smallHeader = true
      } else {
        this.smallHeader = false
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 0;
}
br.small {
  line-height: 130%;
}
p {
  line-height: 1.4;
  margin: 1px;
  max-width: 600px;
}
p.chinese {
  line-height: 1.4;
}
.body {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  font-family: "Andale Mono";
  background-color: #090909;
  background-size: cover;
}
.body-avatar-container {
  padding: 0;
}
.body-avatar {
  width: 210px;
  height: 210px;
  border-radius: 200px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
.body-avatar-small {
  width: 120px;
  height: 120px;
}
.body-container {
  padding: 170px;
  text-align: left;
  font-family: "sans serif";
  /* padding-top: 180px; */
}
.body-container-small {
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 10;
  min-height: 50px;
}
.header-small {
  height: 50px;
  padding-bottom: 15px;
}
.avatar-left {
  position: relative;
  top: 25%;
  width: 70px;
  height: 70px;
  margin-left: 70px;
  /* border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black; */
  display: flex;
  justify-content: left;
  flex-direction: row; 
}
.avatar-right {
  position: relative;
  top: 25%;
  height: 70px;
  margin-right: 0px;
  /* border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black; */
  display: flex;
  justify-content: left;
  flex-direction: row; 
}
.avatar-right-small {
  margin-right: 10px;
}
.avatar-small {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 15px;
}
.avatar:hover {
  cursor: pointer;
}
.avatar-img {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
.avatar-img-small{
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.avatar-name {
  margin-top: 15px;
  margin-left: 10px;
  height: 70px;
  font-weight: 900;
  font-size: 145%;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: "Andale Mono";
  /* text-transform: lowercase; */
  text-decoration: none;
  color: rgb(31, 31, 31);
  min-height: 35px;
  min-width: 190px;
}
.avatar-name:hover {
  color: rgb(91, 91, 91);
}
.avatar-name-small {
  margin-top: 5px;
  margin-left: 5px;
  height: 35px;
  font-weight: 900;
  font-size: 120%;
}
.avatar-about {
  margin-top: 17px;
  margin-right: 65px;
  height: 70px;
  font-weight: 800;
  font-size: 140%;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: "Andale Mono";
  text-decoration: none;
  color: rgb(91, 91, 91);
  min-height: 35px;
}
.avatar-about-small {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 35px;
  height: 35px;
  font-weight: 900;
  font-size: 120%;
}
.introduction-title {
  color: white;
  font-size: 120%;
}
.introduction {
  color: white;
}
.contact-icons {
  position: fixed;
  right: 0px;
  bottom: 10px;
}
.contact-icon-bg {
  position: absolute;
  top: 16px;
  z-index: -10;
  width: 50px;
  height: 40px;
  background-color: rgba(171, 171, 171, 0.515);
  border-radius: 10px;
}
.contact-icon-img {
  width: 50px;
  height: 50px;
}
</style>
